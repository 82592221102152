export type LogLevel = "ERROR" | "WARN" | "INFO" | "LOG" | "DEBUG";
const LogLevels = ["ERROR", "WARN", "INFO", "LOG", "DEBUG"];

export class Logger {
  private static currentLogLevel: LogLevel = "WARN";

  static setLogLevel(level: LogLevel): void {
    Logger.currentLogLevel = level;
  }

  private static getColorScheme(): string {
    return typeof window !== 'undefined' && window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }

  private static getColorByLevel(level: LogLevel): string {
    const colorScheme = Logger.getColorScheme();
    switch (level) {
      case "LOG":
        return colorScheme === "dark" ? "#B0BEC5" : "#37474F";
      case "INFO":
        return colorScheme === "dark" ? "#82B1FF" : "#0D47A1";
      case "WARN":
        return colorScheme === "dark" ? "#FFD54F" : "#FF6F00";
      case "ERROR":
        return colorScheme === "dark" ? "#FF8A80" : "#D32F2F";
      case "DEBUG":
        return colorScheme === "dark" ? "#B0BEC5" : "#37474F";
      default:
        return colorScheme === "dark" ? "white" : "black";
    }
  }

  private static logMessage(level: LogLevel, message?: any, ...optionalParams: any[]): void {
    const shouldLog = LogLevels.indexOf(level) <= LogLevels.indexOf(Logger.currentLogLevel);
    if (!shouldLog) {
      return;
    }

    const formattedMessage = `%c[${level}] Tracker: ${message}`;
    const style = `color: ${Logger.getColorByLevel(level as any)};`;

    switch (level) {
      case "LOG":
        console.log(`${formattedMessage}`, style, ...optionalParams);
        break;
      case "INFO":
        console.info(`${formattedMessage}`, style, ...optionalParams);
        break;
      case "WARN":
        console.warn(`${formattedMessage}`, style, ...optionalParams);
        break;
      case "ERROR":
        console.error(`${formattedMessage}`, style, ...optionalParams);
        break;
      case "DEBUG":
        console.debug(`${formattedMessage}`, style, ...optionalParams);
        break;
      default:
        console.log(`${formattedMessage}`, style, ...optionalParams);
        break;
    }
  }

  static log(message?: any, ...optionalParams: any[]): void {
    Logger.logMessage("LOG", message, ...optionalParams);
  }

  static info(message?: any, ...optionalParams: any[]): void {
    Logger.logMessage("INFO", message, ...optionalParams);
  }

  static warn(message?: any, ...optionalParams: any[]): void {
    Logger.logMessage("WARN", message, ...optionalParams);
  }

  static error(message?: any, ...optionalParams: any[]): void {
    Logger.logMessage("ERROR", message, ...optionalParams);
  }

  static debug(message?: any, ...optionalParams: any[]): void {
    Logger.logMessage("DEBUG", message, ...optionalParams);
  }

}
