import { Platform, SelfDescribingJson } from "@snowplow/browser-tracker";
import { LogLevel } from "./helpers/logger";
import { Tv2NoPage } from "../snowtype/snowplow";

export type Environment = 
  | 'local'
  | 'test' 
  | 'dev' 
  | 'development' 
  | 'qa'
  | 'stage' 
  | 'staging' 
  | 'prod' 
  | 'production';

export interface SnowplowTrackingOptions {
  /**
   * The environment the app is running in.
   * Environments: production, staging, development
   * @default 'development'
   */
  environment?: Environment;
  
  /**
   * Log level
   * @default 'WARN'
   */
  logLevel?: LogLevel;

  /**
   * App ID
   * @default 'tv2no'
   */
  appId?: string;

  /**
   * Platform
   * @default undefined
   * If not set, snowplow sets default to web
   */
  platform?: Platform;
}

export interface SnowplowPageViewOptions {
  /**
   * Used to override the default snowplow page title.
   */
  pageTitle?: string;
  /**
   * Custom contexts to be sent with the page view event.
   */
  contexts?: SelfDescribingJson[];

  /**
   * Wether the page view event should be buffered or not.
   */
  shouldBuffer?: boolean;
}

export interface Asset {
  id: string;
  gpid: string;
  title: string;
  categoryTypeOfAction: string;
  image: string;
  url: string;
  duration: number;
  categoryId: string;
  publishedDate: string;
  tags: string[];
}

export enum Direction {
  FORWARD = 'FORWARD',
  BACK = 'BACK',
}

export type IVideoPulses = {
  firstQuartileFired: boolean,
  secondQuartileFired: boolean,
  thirdQuartileFired: boolean
};