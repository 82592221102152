import { addGlobalContexts } from "@snowplow/browser-tracker";
import { Tv2NoLivesport, Tv2NoLivesportMatchClick, createTv2NoLivesport, trackLivesportUserActionSpec, trackMatchClickSpec, Tv2NoUserAction } from "../../snowtype/snowplow";
import { Logger } from "../helpers/logger";
import { ILivesportTracker } from "./ILivesportTracker";

export class LivesportTracker implements ILivesportTracker {

  setupLivesportContext(data: Tv2NoLivesport): void {
    try {
      addGlobalContexts([ createTv2NoLivesport(data) ]);
      Logger.info(`Livesport tracker initialized`);
    } catch (error) {
      Logger.error('Error during Livesport tracker initialization:', error);
    }
  }

  matchClick(data: Tv2NoLivesportMatchClick): void {
    try {
      trackMatchClickSpec(data);
      Logger.debug(`Match click event tracked`);
    } catch (error) {
      Logger.error(`Error tracking match click event:`, error);
    }
  }

  trackUserAction(data: Tv2NoUserAction): void {
    try {
      trackLivesportUserActionSpec(data);
      Logger.debug(`User action event tracked`);
    } catch (error) {
      Logger.error(`Error tracking user action event:`, error);
    }
  }
}
