import { CommonEventProperties, SelfDescribingJson, PageViewEvent } from '@snowplow/browser-tracker';

type EventType = 'page_view' | 'self_describing';

export interface BufferedEvent<T> {
  type: EventType;
  data: T;
}

export type PageViewEventType = PageViewEvent & CommonEventProperties<Record<string, unknown>>;
export type SelfDescribingEventType = {
  event: SelfDescribingJson;
  context: SelfDescribingJson[];
};

type GenericEventType = PageViewEventType | SelfDescribingEventType;

export interface IBufferManager {
  bufferEvent(event: BufferedEvent<GenericEventType>): void;
  getBufferedEvents(): BufferedEvent<GenericEventType>[];
  clearBufferedEvents(): void;
  processBufferedEvents(processFn: (event: BufferedEvent<GenericEventType>) => void): void;
}

export class BufferManager implements IBufferManager {
  private storageKey: string;

  constructor(storageKey: string) {
    this.storageKey = storageKey;
  }

  bufferEvent(event: BufferedEvent<GenericEventType>): void {
    const bufferedEvents = this.getBufferedEvents();
    bufferedEvents.push(event);
    localStorage.setItem(this.storageKey, JSON.stringify(bufferedEvents));
  }

  getBufferedEvents(): BufferedEvent<GenericEventType>[] {
    return JSON.parse(localStorage.getItem(this.storageKey) ?? '[]');
  }

  clearBufferedEvents(): void {
    localStorage.removeItem(this.storageKey);
  }

  processBufferedEvents(processFn: (event: BufferedEvent<GenericEventType>) => void): void {
    const bufferedEvents = this.getBufferedEvents();
    bufferedEvents.forEach(event => processFn(event));
    this.clearBufferedEvents();
  }
}
