import { LinkClickTrackingPlugin, enableLinkClickTracking } from "@snowplow/browser-plugin-link-click-tracking";
import { CommonEventProperties, PageViewEvent, Platform, SelfDescribingJson, addGlobalContexts, enableActivityTracking, newTracker, trackPageView } from "@snowplow/browser-tracker";
import { Tv2NoPaywallImpression, Tv2NoUserAction, Tv2NoUserLogin, createTv2User, trackPaywallImpressionSpec, trackUserActionSpec, trackUserLoginSpec } from "../../snowtype/snowplow";
import { getCollectorUrl } from "../config";
import { Logger } from "../helpers/logger";
import { Environment, SnowplowPageViewOptions, SnowplowTrackingOptions } from "../types";
import { ICoreTracker } from "./ICoreTracker";
import { BufferManager, BufferedEvent, IBufferManager, PageViewEventType } from "../helpers/BufferManager";
import { getAppId, getPlatform } from "../helpers/utils";

export class CoreTracker implements ICoreTracker {
  private bufferManager: IBufferManager;

  constructor(
    private readonly snowplowTrackingOptions: SnowplowTrackingOptions = {},
    bufferStorageKey: string = 'snowplowBufferedEvents'
  ) {
    this.bufferManager = new BufferManager(bufferStorageKey);
  }

  async initializeTracker(): Promise<void> {
    try {
      const {
        platform: defaultPlatform = "web",
        appId: defaultAppId = "tv2no",
        environment = "development"
      } = this.snowplowTrackingOptions;

      const platform = getPlatform(defaultPlatform);
      const appId = await getAppId(defaultAppId);

      this.setupTracker(appId, platform, environment);
    } catch (error) {
      Logger.error('Error during tracker initialization:', error);
    }
  }


  setupTracker(appId: string, platform: Platform, environment: Environment): void {
    const collectorUrl = getCollectorUrl(environment);

    newTracker('sp', collectorUrl, {
      appId: appId,
      platform: platform,
      discoverRootDomain: true,
      cookieSameSite: "Lax",
      plugins: [
        LinkClickTrackingPlugin()
      ],
    });

    Logger.info(`Snowplow tracker initialized with appId: ${appId}, platform: ${platform}, collectorUrl: ${collectorUrl}`);
  }

  setupUserContext(): void {
    Logger.info("Setting up user context");
  
    let isUserContextSet = false;
  
    const setUserContext = (userId: string | null) => {
      if (isUserContextSet) return;
  
      const userContext = createTv2User({ user_id: userId });
      addGlobalContexts([userContext]);
      isUserContextSet = true;
      Logger.info("User context set", { userId });
    };
  
    const checkAuth0 = (event: any) => {
      if (event.detail.isAuthenticated === undefined || event.detail.userId === undefined) {
        Logger.info("Received Auth0 event without required properties, waiting for valid data");
        return false;
      }
      const userId = event.detail.userId;
      setUserContext(userId);
      return true;
    };
  
    const checkGetLoggedInUser = async () => {
      if (window.TV2 && typeof window.TV2.getLoggedInUser === 'function') {
        try {
          const user = await window.TV2.getLoggedInUser();
          if (user && user.id) {
            setUserContext(user.id);
            return true;
          }
        } catch (error) {
          Logger.error("Error calling getLoggedInUser", error);
        }
      }
      return false;
    };
  
    const auth0Listener = (event: any) => {
      if (checkAuth0(event)) {
        document.removeEventListener('tv2-menu:auth0-ready', auth0Listener);
      }
    };
    document.addEventListener('tv2-menu:auth0-ready', auth0Listener);
  
    checkGetLoggedInUser().then(success => {
      if (!success) {
        setTimeout(async () => {
          if (!isUserContextSet) {
            Logger.info("Performing fallback check");
            await checkGetLoggedInUser();
          }
        }, 2000);
      }
    });
  }
  
  setGlobalContexts(contexts: SelfDescribingJson[]): void {
    try {
      addGlobalContexts(
        contexts
      );
      Logger.info('Global contexts added.');
    } catch (error) {
      Logger.error('Error adding global contexts:', error);
    }
  }

  enableActivityTracking(minimumVisitLength: number = 5, heartbeatDelay: number = 15): void {
    try {
      enableActivityTracking({
        minimumVisitLength: minimumVisitLength,
        heartbeatDelay: heartbeatDelay
      });

      Logger.info(`Activity tracking enabled: minimum visit length = ${minimumVisitLength}s, heartbeat delay = ${heartbeatDelay}s`);
    } catch (error) {
      Logger.error('Error enabling activity tracking:', error);
    }
  }

  trackUserAction(data: Tv2NoUserAction): void {
    try {
      trackUserActionSpec({ ...data });
      Logger.debug(`User action tracked: ${data.actionType}`);
    } catch (error) {
      Logger.error('Error tracking user action:', error);
    }
  }

  trackUserLogin(data: Tv2NoUserLogin): void {
    try {
      trackUserLoginSpec({ ...data });
      Logger.debug(`User login tracked: ${data.userId}`);
    } catch (error) {
      Logger.error('Error tracking user login:', error);
    }
  }

  trackPaywallImpression(paywallImpression: Tv2NoPaywallImpression): void {
    try {
      trackPaywallImpressionSpec({ ...paywallImpression });
      Logger.debug('Paywall impression tracked.');
    } catch (error) {
      Logger.error('Error tracking paywall impression:', error);
    }
  }

  trackPageView(pageViewEvent?: SnowplowPageViewOptions): void {
    try {
      const event: PageViewEvent & CommonEventProperties<Record<string, unknown>> = {
        ...(pageViewEvent?.pageTitle && { title: pageViewEvent.pageTitle }),
        context: pageViewEvent?.contexts || [],
        timestamp: { type: 'dtm', value: Date.now() }
      };

      if (pageViewEvent?.shouldBuffer) {
        const bufferedEvent: BufferedEvent<PageViewEventType> = {
          type: 'page_view',
          data: event,
        };

        this.bufferManager.bufferEvent(bufferedEvent);
        Logger.info('Page view event buffered.');
      } else {
        trackPageView(event);
        Logger.info('Track pageview');
      }
    } catch (error) {
      Logger.error('Error tracking page view:', error);
    }
  }

  processBufferedEvents(): void {
    this.bufferManager.processBufferedEvents(event => {
      const { type, data } = event;
      switch (type) {
        case 'page_view':
          trackPageView(data as PageViewEventType);
          break;
        case 'self_describing':
          // TODO: Come up with a generic way to handle self-describing events
          break;
        default:
          Logger.warn(`Unknown buffered event type: ${type}`);
      }
    });
  }

  enableLinkClickTracking(): void {
    try {
      enableLinkClickTracking();
      Logger.info('Link click tracking enabled.');
    } catch (error) {
      Logger.error('Error enabling link click tracking:', error);
    }
  }
}