import { SelfDescribingJson } from "@snowplow/browser-tracker";
import { createTv2NoAd, createTv2NoLabradorMetadata, Tv2NoAd, Tv2NoLabradorMetadata } from "../snowtype/snowplow";
import { ComponentTracker, IComponentTracker } from "./ComponentTracker";
import { CoreTracker, ICoreTracker } from "./CoreTracker";
import { Logger } from "./helpers/logger";
import { transformMetadata } from "./helpers/utils";
import { ILivesportTracker, LivesportTracker } from "./LivesportTracker";
import { SnowplowTrackingOptions } from "./types";
import { IVideoTracker, VideoTracker } from "./VideoTracker";

export interface ISnowplowTracker {
  /**
   * Core tracker that includes all core snowplow tracking functionalities.
   */
  coreTracker: ICoreTracker;
  /**
   * Video tracker that includes all video tracking functionalities.
   */
  videoTracker: IVideoTracker;
  /**
   * Livesport tracker that includes all livesport tracking functionalities.
   */
  livesportTracker: ILivesportTracker;
  /**
   * Component tracker
   */
  componentTracker: IComponentTracker;

  /**
   * Initializes the snowplow tracker.
   */
  initialize(): Promise<void>;

  /**
   * Triggers page view tracking with necessary contexts
   */
  trackAndProcessPageview(): void;
}

/**
 * Tracker class that consolidates core and video tracking functionalities.
 * This class ensures that all tracking related actions are managed through a single interface,
 * enhancing maintainability and scalability.
 */
export class SnowplowTracker implements ISnowplowTracker {
  public coreTracker: ICoreTracker;
  public videoTracker: IVideoTracker;
  public livesportTracker: ILivesportTracker;
  public componentTracker: IComponentTracker;

  private static instance: SnowplowTracker;
  private initializedPromise: Promise<void> | null = null;

  constructor(options: SnowplowTrackingOptions = {}) {
    const { logLevel = "WARN" } = options;
    Logger.setLogLevel(logLevel);
    this.coreTracker = new CoreTracker(options);
    this.videoTracker = new VideoTracker();
    this.livesportTracker = new LivesportTracker();
    this.componentTracker = new ComponentTracker();
  }

  public static getInstance(options: SnowplowTrackingOptions = {}): SnowplowTracker {
    if (typeof window !== "undefined" && window.snowplowTracker) {
      Logger.warn("Snowplow tracker already initialized");
      return window.snowplowTracker;
    }

    if (!SnowplowTracker.instance) {
      SnowplowTracker.instance = new SnowplowTracker(options);
      if (typeof window !== "undefined") {
        Logger.info("Snowplow tracker initialized");
        window.snowplowTracker = SnowplowTracker.instance;
      }
    }
    return SnowplowTracker.instance;
  }

  public initialize(): Promise<void> {
    if (this.initializedPromise) {
      return this.initializedPromise;
    }

    this.initializedPromise = this.initializeInternal();
    return this.initializedPromise;
  }

  public trackAndProcessPageview(): void {
    const adUnitPathContext = this.getAdUnitPathContext();
    this.coreTracker.trackPageView({
      contexts: adUnitPathContext ? [adUnitPathContext] : []
    });
  }

  private async initializeInternal(): Promise<void> {
    try {
      await this.coreTracker.initializeTracker();

      this.coreTracker.setupUserContext();
      this.coreTracker.enableActivityTracking();
      this.coreTracker.enableLinkClickTracking();

      const metadataContext = this.getMetadataContext();
      if (metadataContext) {
        this.coreTracker.setGlobalContexts([metadataContext]);
      }

      Logger.info("Snowplow tracker fully initialized");
    } catch (error) {
      Logger.error("Error during Snowplow tracker initialization", error);
      throw error;
    }
  }

  private getAdUnitPathContext(): SelfDescribingJson | null {
    if (typeof window !== "undefined" && window.gptSetup?.adUnitPath) {
      const adContext: Tv2NoAd = {
        adUnitPath: window.gptSetup.adUnitPath,
      };

      return createTv2NoAd(adContext);
    }
    return null;
  }

  private getMetadataContext(): SelfDescribingJson | null {
    if (typeof window !== "undefined" && window.lab_metadata) {
      const labMetadata: Tv2NoLabradorMetadata = transformMetadata(window.lab_metadata);
      return createTv2NoLabradorMetadata(labMetadata);
    }
    return null;
  }
}
