import { Tv2Component, Tv2NoUserAction, createTv2Component, trackUserActionSpec } from "../../snowtype/snowplow";
import { Logger } from "../helpers/logger";
import { IComponentTracker } from "./IComponentTracker";

export class ComponentTracker implements IComponentTracker {
  trackUserAction(data: Tv2NoUserAction, context: Tv2Component): void {
    try {
      const componentContext = createTv2Component(context);
      trackUserActionSpec(
        {
          ...data,
          context: [componentContext],
        }
      );
      Logger.debug(`User action tracked: ${data.actionType}`);
    } catch (error) {
      Logger.error('Error tracking user action:', error);
    }
  }
}